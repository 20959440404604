<footer class="footer">
  <!-- <div class="container-fluid">
      <div class="row">
          <div class="col-sm-6">

              <p style="color: #9c9c9c;">{{'FOOTER.TITULO_PIE_1'| translate}}</p>
          </div>
          <div class="col-sm-6">
              <div style="text-align: end;">

                  <p style="color: #9c9c9c;">© {{year}} - {{'FOOTER.TITULO_PIE_2'| translate}}</p>
              </div>
          </div>
      </div>
  </div> -->
</footer>
