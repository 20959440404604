import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";


//Nota Importante: las respuestas de Http está Trabajando con un Interceptador de errores
//que se encuentra an la ruta app/core/helpers/error/interceptor.ts este interceptor se declara
//en app.module.ts

@Injectable({
  providedIn: 'root'
})
export class HttphandlerService {

  public url: string;

  constructor(
    private http  : HttpClient
  ) { }

      public httpGetData(url){
        return this.http.get(url)

      }

      public httpPostData(url, data, headers={}){

        return this.http.post(url, data, headers);
      }

      public httpPachData(url, data, headers={}){

        return this.http.patch(url,data,headers);
      }

}
