import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map  } from 'rxjs/operators';
import { WebsocketService } from '../../core/services/websocket.service';
import { HttphandlerService } from '../../core/services/httphandler.service';
import { environment } from '../../../environments/environment';

//const URL = 'wss://xps2fmlaw5.execute-api.us-east-1.amazonaws.com/production';

@Injectable({
  providedIn: 'root'
})
export class AlarmasService {

    public messages    : Subject<any> = new Subject<any>();
    public alarmas      : any[];
    public alarmaslegth: number;

    options = {
      url: environment.urlapi.webSocket,
      pingTimeout: 15000,
      pongTimeout: 10000,
      reconnectTimeout: 30000,
      pingMsg: '{"action": "ping"}'
    }

    constructor(private wsService: WebsocketService,private httpService: HttphandlerService) {

      this.connectWebSocket(this.options);

    }

    public connectWebSocket(options) {
      this.messages = <Subject<any>>this.wsService
      .connect(options)
      .pipe(
        map((response: MessageEvent): any => {

          let data = JSON.parse(response.data);

          return {
            data: data
          };
     }));
    }

    public sendMessage(message) {
      this.messages.next(message);
    }


}



