import { Injectable } from '@angular/core';
import { Observable, Subject, Observer } from 'rxjs';
import WebsocketHeartbeatJs from 'websocket-heartbeat-js';


@Injectable({
  providedIn: 'root'
})

export class WebsocketService {



  constructor() { }

  private subject: Subject<any>;
  //private subjectData: Subject<number>;


  public connect(options): Subject<any> {
    if (!this.subject) {
      this.subject = this.create(options);
    }
    return this.subject;
  }

  private create(options): Subject<any> {
    //const ws = new WebSocket(url);
    const wsHeartbeatJs = new WebsocketHeartbeatJs(options);

    const observable = Observable.create(
      (obs: Observer<MessageEvent>) => {
        wsHeartbeatJs.onmessage = obs.next.bind(obs);
        wsHeartbeatJs.onerror = obs.error.bind(obs);
        wsHeartbeatJs.onclose = obs.complete.bind(obs);

        return wsHeartbeatJs.close.bind(wsHeartbeatJs);
      });

    const observer = {
      next: (data: Object) => {
        //if (ws.readyState === WebSocket.OPEN) {
          wsHeartbeatJs.send(JSON.stringify(data));
        //}
      }
    };

    return Subject.create(observer, observable);
  }
}
