import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor() { }

  // Alertas de las libreria  sweetalert2

  basicMessage() {
    Swal.fire("No cuenta con los privilegios para esta funcionalidad.");
  }

  titleTextRecom() {
    Swal.fire("Advertencia!", "Debe llenar todos los campos..", "warning");
  }

  successmsgGuardaRecom() {
    Swal.fire("Recomendación Creada con Éxito!", "", "success");
  }

  confirmRechazarRecom() {
    Swal.fire({
      title: "Desea Rechazar la Recomendación?",
      //text: 'You won\'t be able to revert this!',
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00cc00",
      cancelButtonColor: "#154f87",
      confirmButtonText: "Save ",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Rechazada!", "La Recomendación fué Rechazada.", "success");
      }
    });
  }

  titleText() {
    Swal.fire("Advertencia!", "Debe llenar todos los campos..", "warning");
  }

  successmsgGuardar() {
    Swal.fire("Dato Creado con Éxito!", "", "success");
  }

  alertaTextMaqBrida() {
    Swal.fire("Advertencia!", "El Elemento Seleccionado No es Una Brida o No Tiene Datos para Mostrar.", "warning");
  }
  alertaTextMaqSelec() {
    Swal.fire("Advertencia!", "Debe Seleccionar un Elemento.", "warning");
  }
  alertaTextMaqSinDatos() {
    Swal.fire("Advertencia!", "El Elemento Seleccionado No Tiene Datos para Mostrar.", "warning");
  }
  alertaTextMaqElemNoEncon() {
    Swal.fire("Advertencia!", "Elemento No Encontrado.", "warning");
  }
  alertaTextArchivoCVS() {
    Swal.fire("Advertencia!", "El Archivo Seleccionado No es CSV.", "warning");
  }

  confirmRechazar() {
    Swal.fire({
      title: "Desea Rechazar los Datos?",
      //text: 'You won\'t be able to revert this!',
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Save ",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Rechazado!", "Los fueron Rechazados.", "success");
      }
    });
  }

  position() {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "No cuenta con los privilegios para esta funcionalidad.",
      showConfirmButton: false,
      timer: 3500,
    });
  }

  imageHeader() {
    Swal.fire({
      title: "No cuenta con los privilegios para esta funcionalidad.",
      text: "",
      imageUrl: "assets/images/Reliabytics/reliabytics-15-1.png",
      imageHeight: 90,
      confirmButtonColor: "#154f87",
    });
  }

  alertaWarning(title: string,text: string =''){
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: false,
      cancelButtonColor: "#154f87",
      confirmButtonColor: "#154f87",
    });
  }
  alertaSuccess(title: string, text: string =''){
    Swal.fire({
      title: title,
      text: text,
      icon: "success",
      showCancelButton: false,
      cancelButtonColor: "#154f87",
      confirmButtonColor: "#154f87",
    });
  }

  alertaInformacion(text: string){
    Swal.fire({
      title: text,
      text: "",
      imageUrl: "assets/images/Reliabytics/reliabytics-15-1.png",
      imageHeight: 90,
      confirmButtonColor: "#154f87",
    });
  }

  infoReliabytics(text: string) {
    Swal.fire({

      title: text,
      text: "",
      imageUrl: "assets/images/Reliabytics/reliabytics-15-1.png",
      imageHeight: 90,
      confirmButtonColor: "#154f87",
    });
  }

 soporteReliabytics(title, html: string) {
    Swal.fire({

      title: title,
      html: html,
      imageUrl: "assets/images/Reliabytics/reliabytics-15-1.png",
      imageHeight: 90,
      confirmButtonColor: "#154f87",
    });
  }
}
