import { Component , OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit  {

    subscription: Subscription;

    constructor(private translate: TranslateService, private router: Router) {

     // Detecta la Reload del navegador y redirecciona a la pagina dashboard "Home"
     this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;

        }
     });

     this.translate.addLangs(['en', 'es']);

     //Toma el idioma del Browser
     const browserLang = translate.getBrowserLang();

     // this language will be used as a fallback when a translation isn't found in the current language
     this.translate.setDefaultLang(browserLang);

     // the lang to use, if the lang isn't available, it will use the current loader to get them
     this.translate.use(browserLang);


   }

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}

// import { Component , OnInit} from '@angular/core';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent implements OnInit  {

//   ngOnInit() {
//     // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
//   }
// }
