// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  defaultauth: 'aws',

  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  confirm: {
   email: '',
   password: ''
  },
  urlapi:{
   default:'https://zgbo8ac5gh.execute-api.us-east-1.amazonaws.com/pocbackend/',
   intervencionequipos:'https://cyqzq2zi4h.execute-api.us-east-1.amazonaws.com/pocbackend/',
   intervencionequiposfuga:'https://rkymk5y6g1.execute-api.us-east-1.amazonaws.com/pocbackend/',
   jerarquiaequipos:'https://jjcm8l4rei.execute-api.us-east-1.amazonaws.com/pocbackend/',
   juntasbridadas:'https://6338x24t1i.execute-api.us-east-1.amazonaws.com/pocbackend/',
   maquetaforge:'https://api.modelo3d.reliabytics.com/api/forge/oauth/token',
   topbar:"https://w40cwl5ok0.execute-api.us-east-1.amazonaws.com/pocbackend/",

   recomendacion_aviso:"https://qwuhk7pc6e.execute-api.us-east-1.amazonaws.com/pocbackend/",
   recomendacion_comp:"https://3nqefd0q0l.execute-api.us-east-1.amazonaws.com/pocbackend/",

   recomendacion:'https://apirecom.reliabytics.com/',
   rondasoperacionales:'https://apironda.reliabytics.com/',
   rondasoperacionales_tarea:'https://apitarea.reliabytics.com/',
   //ciclovida:"https://rf24vlrjri.execute-api.us-east-1.amazonaws.com/",
   ciclovida:"https://apirbi.reliabytics.com/",
   inspeccion:"https://apiinspeccion.reliabytics.com/",
   inspeccion_tarea:"https://apitarea.reliabytics.com/",
   cualitativo:"https://apicualitativo.reliabytics.com/",

   default_:'https://owmjhxvqba.execute-api.us-east-1.amazonaws.com/dev/',  //temp : Pasar a Default
   usuario_roles:'https://ft7hbykis7.execute-api.us-east-1.amazonaws.com/pocbackend/v1/', //temp : pasar a default

   dataloadersrbi:"https://owmjhxvqba.execute-api.us-east-1.amazonaws.com/dev/",
   panelrbi:"https://eupranim6e.execute-api.us-east-1.amazonaws.com/pocbackend/",

  //  cualitativo:"https://apicualitativo.reliabytics.com/",

   alarma:"https://rkvwv2k3qa.execute-api.us-east-1.amazonaws.com/tenant/",
   quicksight:'',
   webSocket:'wss://xps2fmlaw5.execute-api.us-east-1.amazonaws.com/production/',
   wssgetAlarmas:"getAlarmas",
   wssupdateAlarma:"updateAlarma"
  },
  identityPoolId:'us-east-1:2108f6b3-33f1-4072-a799-510bfb30d754',
  bucketName: 'reliabytics-cardoniv-desa'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
