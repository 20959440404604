/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//// Datos de RBI Desa //////////////amplify_backend_manager_d3fz4ms1huqp6a   amplify_backend_manager_d3fz4ms1huqp6a

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:2108f6b3-33f1-4072-a799-510bfb30d754",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_lmezZLPLh",
  "aws_user_pools_web_client_id": "1fqfc94hek70eagq5nogppuqbd",
  "oauth": {},
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cloud_logic_custom": []

};

export default awsmobile;


//// Datos de POC  //////////////

// const awsmobile = {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:5a108def-464f-4a3d-ae44-7359c0e00e23",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_5T1nCuLkX",
//     "aws_user_pools_web_client_id": "ch9bsp63fb5n4krlsbb1ncod5",
//     "oauth": {},
//     "federationTarget": "COGNITO_USER_POOLS",
//     "aws_cloud_logic_custom": [
//         {
//             "name": "apiQS",
//             "endpoint": "https://p66ld3bota.execute-api.us-east-1.amazonaws.com/pocbackend",
//             "region": "us-east-1"
//         },
//         {
//             "name": "quicksight",
//             "endpoint": "https://zgbo8ac5gh.execute-api.us-east-1.amazonaws.com/pocbackend",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiFindings",
//             "endpoint": "https://7c72znlhd6.execute-api.us-east-1.amazonaws.com/pocbackend",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiRecomendacion",
//             "endpoint": "https://v959g1bpvf.execute-api.us-east-1.amazonaws.com/pocbackend",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiEquipos",
//             "endpoint": "https://w40cwl5ok0.execute-api.us-east-1.amazonaws.com/pocbackend",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiJerarquia",
//             "endpoint": "https://jjcm8l4rei.execute-api.us-east-1.amazonaws.com/pocbackend",
//             "region": "us-east-1"
//         }
//     ]

// };


// export default awsmobile;


//// Datos de DEV2  //////////////
// const awsmobile = {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:39ac6a09-1bb7-4b4a-a445-b6e06e81c7d3",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_qTD0QwMBd",
//     "aws_user_pools_web_client_id": "6teotde7gl2k5h0se9fp7iu4tc",
//     "oauth": {},
//     "federationTarget": "COGNITO_USER_POOLS",
//     "aws_cloud_logic_custom": [{
//             "name": "apiQS",
//             "endpoint": "https://j0gd6v085k.execute-api.us-east-1.amazonaws.com/dev",
//             "region": "us-east-1"
//         },
//         {
//             "name": "quicksight",
//             "endpoint": "https://rxqhpduape.execute-api.us-east-1.amazonaws.com/dev",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiFindings",
//             "endpoint": "https://k0r4891gxg.execute-api.us-east-1.amazonaws.com/dev",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiRecomendacion",
//             "endpoint": "https://0npd1r9thg.execute-api.us-east-1.amazonaws.com/dev",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiEquipos",
//             "endpoint": "https://o58os37wdd.execute-api.us-east-1.amazonaws.com/dev",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiJerarquia",
//             "endpoint": "https://lzkrkxgwac.execute-api.us-east-1.amazonaws.com/dev",
//             "region": "us-east-1"
//         },
//         {
//             "name": "apiUsuarios",
//             "endpoint": "https://olodqrry73.execute-api.us-east-1.amazonaws.com/dev",
//             "region": "us-east-1"
//         }
//     ]
// };


// export default awsmobile;
