import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { browserRefresh } from '../../app.component';

@Component({
  selector: 'app-pagefault',
  templateUrl: './pagefault.component.html',
  styleUrls: ['./pagefault.component.scss']
})
export class PagefaultComponent implements OnInit {

  errHttp    : string;
  errMessage : string;
  errStatus  : string;

  constructor(
    private router         : Router,
    private route          : ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Detecta la Reload del navegador y redirecciona a la pagina dashboard "Home"
    if(browserRefresh==true){

      this.router.navigate(["/"]);
    }

    // Recibe el mensaje de error
    this.errHttp     = this.route.snapshot.paramMap.get("errorhttp");
    this.errMessage  = this.route.snapshot.paramMap.get("message");
    this.errStatus   = this.route.snapshot.paramMap.get("status");

  }

}

