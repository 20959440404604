/// <reference types="@angular/localize" />


import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Amplify, Auth } from 'aws-amplify';
import { API } from '@aws-amplify/api';
import awsmobile from './aws-exports';


import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key: JavaScript 21.2.10
//registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5UdERiUX1ec3ZTQGRa');
// Registering Syncfusion license key: JavaScript 25.x.x
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5WdEViUXpcc3RdRGJf');


Amplify.configure(awsmobile);
Auth.configure(awsmobile);
API.configure(awsmobile);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
