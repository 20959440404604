import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';

const routes: Routes = [
 { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
 { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
 { path: '**', redirectTo: '/account/login'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top' })],  //,scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy', onSameUrlNavigation:'reload'
  exports: [RouterModule]
})

export class AppRoutingModule { }



// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';

// import { AuthGuard } from './core/guards/auth.guard';
// import { LayoutComponent } from './layouts/layout.component';

// const routes: Routes = [
//   { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
//   // tslint:disable-next-line: max-line-length
//   { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
//   exports: [RouterModule]
// })

// export class AppRoutingModule { }
